import React from "react"
import { Link } from "gatsby"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Media from "react-bootstrap/Media"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Breadcrumbs from "../../components/breadcrumb"

const PublicationsPage = () => {
  const crumbs = [
    {
      title: "Archives",
      slug: "/archives"
    }
  ]

  return (
    <Layout>
      <SEO title="Print Publications" />
      <Row>
        <Col id="page-title" className="col col-12">
          <h1 className="title">Print Publications</h1>
        </Col>
      </Row>
      <Breadcrumbs crumbs={crumbs} />
      <Row as="article" id="content">
        <Col>
          <Media as="section">
            <Media.Body>
              <Link to="/archives/publications/research-engineer">
                <h3>The Research Engineer</h3>
              </Link>
              The Research Engineer is one of the earliest existing publications
              produced by the State Engineering Experiment Station. It is a
              technical magazine that detailed early research, such as “The
              Puzzling Role of Ionized Gases” and “TV’s Tiniest Show.” This
              archive contains issues published between January 1956 and October
              1961.
            </Media.Body>
            <Link tabIndex="-1" to="/archives/publications/research-engineer">
              <img
                className="p-3 d-none d-md-block"
                src="/images/publications/research-engineer-thumbnail.jpg"
                alt="Research Engineer Publications thumbnail"
              />
            </Link>
          </Media>

          <Media as="section">
            <Media.Body>
              <Link to="/archives/publications/news-releases">
                <h3>News Releases</h3>
              </Link>
              From improving UHF antennas to finding a “non-controversial” way
              to use marijuana for the treatment of glaucoma — research at the
              State Engineering Experiment Station/GTRI, has helped solve some
              of the world’s toughest problems. This archive contains news
              releases issued by EES/GTRI spanning a 15-year period between
              January 11, 1978, and February 24, 1993.
            </Media.Body>
            <Link tabIndex="-1" to="/archives/publications/news-releases">
              <img
                className="p-3 d-none d-md-block"
                src="/images/publications/press-release-thumbnail.jpg"
                alt="News Releases thumbnail"
              />
            </Link>
          </Media>

          <Media as="section">
            <Media.Body>
              <Link to="/archives/publications/ees-notes-reports">
                <h3>EES Notes/Reports</h3>
              </Link>
              EES Notes and EES Report were newsletters produced by the State
              Engineering Experiment Station, which were used to showcase
              research advances, such as innovative millimeter radome designs
              and techniques for improving the air quality within hospitals.
              This archive includes a number of issues produced between December
              30, 1969, and January, 1982.
            </Media.Body>
            <Link tabIndex="-1" to="/archives/publications/ees-notes-reports">
              <img
                className="p-3 d-none d-md-block d-none d-md-block"
                src="/images/publications/ees-notes-thumbnail.jpg"
                alt="EES Notes/Reports thumbnail"
              />
            </Link>
          </Media>

          <Media as="section">
            <Media.Body>
              <Link to="/archives/publications/station-news">
                <h3>Station News</h3>
              </Link>
              Station News was a monthly publication produced for employees of
              the State Engineering Experiment Station. The newsletter was
              filled with interesting stories about EES research, from the
              opening of the Radar “Eskimo Camp” in the Arctic Circle to the
              study of molten metal solidification in the absence of gravity.
              There are also many human interest stories featuring the people of
              EES. This archive contains many issues published between October
              1972 and September 1984.
            </Media.Body>
            <Link tabIndex="-1" to="/archives/publications/station-news">
              <img
                className="p-3 d-none d-md-block"
                src="/images/publications/station-news-thumbnail.jpg"
                alt="Station News thumbnail"
              />
            </Link>
          </Media>

          <Media as="section">
            <Media.Body>
              <Link to="/archives/publications/connector">
                <h3>GTRI Connector</h3>
              </Link>
              The GTRI Connector was a newsletter produced by the Georgia Tech
              Research Institute for its employees. It contains interesting
              research features as well as fun stories about the people of GTRI.
              This archive contains issues published between October 1984 and
              August 1997.
            </Media.Body>
            <Link tabIndex="-1" to="/archives/publications/connector">
              <img
                className="p-3 d-none d-md-block"
                src="/images/publications/connector-thumbnail.jpg"
                alt="GTRI Connector Publications thumbnail"
              />
            </Link>
          </Media>

          <Media as="section">
            <Media.Body>
              <Link to="/archives/publications/insider">
                <h3>GTRInsider</h3>
              </Link>
              The GTRInsider is a current publication of the Georgia Tech
              Research Institute. Designed for employees, retirees, and friends
              of GTRI, the publication includes research highlights and features
              about GTRI people and activities. This archive includes issues
              produced between 2005 and 2008.
            </Media.Body>
            <Link tabIndex="-1" to="/archives/publications/insider">
              <img
                className="p-3 d-none d-md-block"
                src="/images/publications/gtri-insider-thumbnail.jpg"
                alt="GTRInsider publications thumbnail"
              />
            </Link>
          </Media>

          <Media as="section">
            <Media.Body>
              <Link to="/archives/publications/annual-reports">
                <h3>Annual Reports</h3>
              </Link>
              Annual Reports produced by the State Engineering Experiment
              Station/Georgia Tech Research Institute provide some of the most
              detailed information about the research activities of the
              organization. Many feature color photography and
              easy-to-understand descriptions of EES/GTRI research programs.
              This archive includes select annual reports produced between 1955
              and 2007.
            </Media.Body>
            <Link tabIndex="-1" to="/archives/publications/annual-reports">
              <img
                className="p-3 d-none d-md-block"
                src="/images/publications/annual-reports-thumbnail.jpg"
                alt="Annual Reports thumbnail"
              />
            </Link>
          </Media>

          <Media as="section">
            <Media.Body>
              <Link to="/archives/publications/other-publications">
                <h3>Other Publications</h3>
              </Link>
              Over the years, EES/GTRI has produced many different printed
              pieces used to promote research activities. This archive contains
              some of the historical brochures and booklets showcasing EES/GTRI
              capabilities — past and present.
            </Media.Body>
            <Link tabIndex="-1" to="/archives/publications/other-publications">
              <img
                className="p-3 d-none d-md-block"
                src="/images/publications/other-pubs-thumbnail.jpg"
                alt="Generic publication thumbnail"
              />
            </Link>
          </Media>
        </Col>
      </Row>
    </Layout>
  )
}

export default PublicationsPage
